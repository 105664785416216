/* You can add global styles to this file, and also import other style files */
@import '_styles/main.scss';


body {
  margin: 0;
  padding: 0;
  font-size: 1rem;
  font-family: 'Poppins-Regular';
  color: var(---font-color);
}

@media (max-width: 1280px) {

  /* Your styles for 125% scaling here */
  html {
    font-size: 12px;
    //color: green;
  }
}


@media (min-width:1281px) and (max-width: 1536px) {

  /* Your styles for 150% scaling here */
  html {
    font-size: 14px;
    //color: blue;
  }
}

@media (min-width:1537px) and (max-width: 1920px) {

  /* Your styles for 100% scaling here */
  html {
    font-size: 18px;
    //color: red;
  }
}



* {
  scrollbar-width: thin;
  scrollbar-color: var(--scroll-bg-color) var(--doc-box-bg-color);
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 10px;
}

*::-webkit-scrollbar:horizontal {
  height: 10px;
}

*::-webkit-scrollbar-track {
  background: var(--doc-box-bg-color);
}

*::-webkit-scrollbar-thumb {
  background-color: var(--scroll-bg-color);
  border-radius: 5px;
  border: 3px none #ffffff;
}
