.d-flex {
    display: flex;
    align-items: center;
}

.d-none {
    display: none !important;
}

.cur-pointer {
    cursor: pointer;
}

.text-align {
    text-align: center;
}

.w-100 {
    width: 100%;
}

.icon25 {
    width: 25px;
    height: 25px;
}

.close-icon {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid white;
    border-radius: 50%;
    font-size: 15px;
    text-decoration: none;
    color: white;
    background-color: #d80d0d;
    cursor: pointer;
}

// Popup related styles
.overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(18, 51, 91, 0.41);
    transition: opacity 500ms;
    display: none;
    z-index: 50;
}

.overlay:target {
    visibility: visible;
    opacity: 1;
}

.popup {
    margin: auto;
    top: 50%;
    transform: translateY(-50%);
    padding: 20px;
    background: #fff;
    border-radius: 5px;
    width: 70%;
    position: relative;
}

.popup h2 {
    margin-top: 0;
    color: #333;
    font-family: Tahoma, Arial, sans-serif;
}

.popup .close {
    position: absolute;
    top: -15px;
    right: -15px;
    @extend .close-icon;
}

.popup .content {
    max-height: calc(100vh - 150px);
    overflow: auto;
}

// Tooltip
.tooltip {
    position: relative;

    .tooltiptext {
        white-space: nowrap;
        display: none;
        width: auto;
        background-color: rgba(49, 58, 70, 0.73);
        color: #fff;
        text-align: center;
        border-radius: 2px;
        padding: 5px 10px;
        position: absolute;
        z-index: 1;
        top: -5px;
        left: 45px;
        opacity: 0;
        transition: opacity 0.3s;

        &::after {
            content: "";
            position: absolute;
            top: 29%;
            left: -12px;
            border-width: 6px;
            border-style: solid;
            border-color: transparent rgba(49, 58, 70, 0.73) transparent transparent;
        }
    }

    &:hover .tooltiptext {
        display: block;
        opacity: 1;
    }

}

.error {
    color: red;
    font-size: var(--font-size-small);
}

.pointer-none {
    pointer-events: none;
}