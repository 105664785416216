.special-btn {
  color: var(--input-background-color);
  padding: var(--gap-sm) var(--gap-lg) !important;
}

.btn {
  font-size: var(--font-size-md);
  padding: var(--gap-sm);
  border-radius: 3px;
  cursor: pointer;
  font-family: 'Poppins-Regular';
  border-style: none;
  display: flex;
  align-items: center;
  justify-content: center;

  &:disabled {
    opacity: 0.5;
  }
}

.btn-primary {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  color: var(--input-background-color);
}

.btn-secondary {
  background-color: var(--secondary-button-color);
  border-color: var(--secondary-button-color);
  color: var(--input-background-color);
}

.btn-danger {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  color: var(--primary-color);
}

.btn-1 {
  background-color: var(--btn-color-1);
  border-color: var(--btn-color-1);
  @extend .special-btn;
}

.btn-2 {
  background-color: var(--btn-color-2);
  border-color: var(--btn-color-2);
  @extend .special-btn;
}

.btn-3 {
  background-color: var(--btn-color-3);
  border-color: var(--btn-color-3);
  @extend .special-btn;
}

.btn-save {
  background-color: var(--btn-color-save);
  border-color: var(--btn-color-save);
  @extend .special-btn;
}

.btn-4 {
  background-color: var(--btn-color-4);
  border-color: var(--btn-color-4);
  @extend .special-btn;
}

.btn-5 {
  background-color: var(--btn-color-5);
  border-color: var(--btn-color-5);
  @extend .special-btn;
}

.btn-6 {
  background-color: var(--btn-color-6);
  border-color: var(--btn-color-6);
  @extend .special-btn;
}

.btn-8 {
  background-color: var(--btn-color-8);
  border-color: var(--btn-color-8);
  @extend .special-btn;
}

.btn-log {
  background-color: var(--btn-color-log);
  border-color: var(--btn-color-log);
  @extend .special-btn;
}

.btn-no {
  border: 1px solid var(--btn-color-no);
  background-color: var(--input-background-color);
  padding: var(--gap-xs) var(--gap-xl) !important;
}

.btn-yes {
  border: 1px solid var(--btn-color-yes);
  background-color: var(--input-background-color);
  padding: var(--gap-xs) var(--gap-xl) !important;
}

.btn-group {
  background-color: var(--btn-color-7);
  border-color: var(--btn-color-7);
  gap: 10px;
  @extend .special-btn;
}

@media (min-width:1281px) and (max-width: 1536px),
(max-width: 1280px) {
  .special-btn {
    padding: var(--gap-xs) var(--gap-md) !important;
  }
}