.formio-form-group {
  color: var(--foi-label-color);
  input[type="text"],
  input[type="email"],
  select {
    @extend .input;
    width: calc(100% - 20px);
  }
  textarea {
    @extend .textarea;
    width: calc(100% - 20px);
  }
  .checkbox {
    height: auto;
    padding: none;
  }
}
.formio-loader {
  min-height: 30px !important;
}
