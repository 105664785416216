@font-face {
    font-family: 'Poppins-Regular';
    src:
        url('../assets/fonts/Poppins-Regular.ttf') format('truetype');
    //font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins-Medium';
    src: url('../assets/fonts/Poppins-Medium.ttf') format('truetype');
    //font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins-SemiBold';
    src: url('../assets/fonts/Poppins-SemiBold.ttf') format('truetype');
    //font-weight: 900;
    font-style: italic;
    font-display: swap;
}

:root {
    /**
    * Font size variables 
        based on basesize = 18px (100% - 1920x1080)
    */
    --font-size-regular: 1rem; // 18px
    --font-size-sm: 0.625rem; // 11.25px
    --font-size-md: 0.875rem; // 15px
    --font-size-lg: 1.25rem; // 22.5px
    --font-size-xl: 1.22rem; // 22px
    --font-size-small: 0.75rem; // 13.5px
    --font-size-login-header: 1.875rem;
    --font-size-login-label: 1.125rem;
}