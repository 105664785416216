:root {
    /**
    * Gap, Margin, Padding variables
    */
    --gap-xs: 5px;
    --gap-sm: 10px;
    --gap-md: 15px;
    --gap-lg: 20px;
    --gap-xl: 25px;
    --gap-xxl: 30px;
    --gap-xxxl: 40px;
}