.input {
  //height: var(--gap-lg);
  outline: none;
  padding: var(--gap-xs) var(--gap-sm);
  border: 1px solid var(--input-border-color);
  border-radius: 3px;
  font-family: 'Poppins-Regular';
  font-size: var(--font-size-md);
}

.textarea {
  padding: var(--gap-xs) var(--gap-sm);
  border: 1px solid var(--primary-color);
  outline: none;
  border: 1px solid var(--input-border-color);
}

.select {
  //height: var(--gap-xxl);
  padding: var(--gap-xs);
}

.checkbox {
  height: 45px;
  padding: var(--gap-xs) var(--gap-sm);
}

.radio {
  height: 45px;
  padding: var(--gap-xs) var(--gap-sm);
}